<template>
  <v-flex ma-4 d-flex class="game-feed">
    <v-layout column>
      <v-layout row wrap>
        <v-flex xs3 mx-2>
          <h2>Search Instructions</h2>
        </v-flex>
        <v-flex xs3 mx-2 class="game-feed-description">
          <v-text-field
            v-model="searchText"
            label="Missions Instrucitons to match in part"
          />
        </v-flex>
        <!-- <v-flex xs3 mx-2 class="game-feed-description">
          <v-switch v-model="searchName" label="Match Name" />
        </v-flex> -->
      </v-layout>
      <v-layout row wrap>
        <v-flex xs3 mx-2>
          <h2>Replace With</h2>
        </v-flex>
        <v-flex xs3 mx-2 class="game-feed-description">
          <v-text-field v-model="replaceText" label="Replace with" />
        </v-flex>
        <v-flex xs3 mx-2 class="game-feed-description">
          <v-btn :loading="loading" class="red" @click="searchAndReplace()"
            >Replace
          </v-btn>
        </v-flex>
      </v-layout>
      <v-layout column>
        <v-flex v-for="(item, i) in results" :key="i" xs12 mx-2>
          <b
            >{{ i + 1 }}. GAME: {{ item.game.name }}" {{ item.name }} -
            {{ item.title }}
          </b>
          -
          {{ showSearch(item.instructions) }}
        </v-flex>
      </v-layout>
    </v-layout>
  </v-flex>
</template>

<script>
// import { mapGetters } from "vuex"
import { fetchGamesByRunStatus, fetchMissions } from "@/services/game.service"
import { Game } from "@/helpers"
import { db } from "@/firebase"
import _ from "lodash"

export default {
  name: "Scripts",
  data() {
    return {
      mission: {},
      searchText: null,
      searchName: false,
      replaceText: null,
      replaceValue: null,
      replaceField: null,
      userAudios: [],
      userAudiosObj: {},
      games: [],
      localMissions: [],
      loading: false
    }
  },
  async created() {
    console.log("ASST SLIDE CREATED")
    this.loading = true
    const path = "org/" + this.orgID
    const ref = db
      .ref(`${path}/games`)
      .orderByChild("runStatus")
      .equalTo("Masters")
    const snapshot = await ref.once("value")
    this.games = Object.values(snapshot.val() || {})
  },
  async mounted() {
    const { orgID } = this.$store.getters
    const games = await fetchGamesByRunStatus({
      orgID,
      value: Game.GAME_RUN_STATUS.MASTERS
    })
    const IDs = Object.keys(games || {})
    const promises = IDs.map(gameID => fetchMissions({ orgID, gameID }))
    const res = await Promise.all(promises)
    const flattenedMissions = _.flatten(
      res.map(missions => Object.values(missions))
    )
    this.localMissions = flattenedMissions
      .filter(mission => IDs.includes(mission.gameID))
      .map(mission => {
        const game = games[mission.gameID]
        return {
          ...mission,
          game: {
            name: game.name
          },
          clientID: game.clientID
        }
      })
    this.loading = false
  },
  computed: {
    orgID() {
      return this.$store.getters.orgID
    },
    results() {
      if (this.searchText) {
        if (!this.searchName) {
          return this.localMissions.filter(item =>
            item.instructions
              .toLowerCase()
              .includes(this.searchText.toLowerCase())
          )
        } else {
          return this.localMissions.filter(item =>
            item.name.toLowerCase().includes(this.searchText.toLowerCase())
          )
        }
      } else {
        return null
      }
    }
  },
  methods: {
    async searchAndReplace() {
      for (var i in this.results) {
        console.log("MISSION", this.results[i])
        var c = this.results[i].instructions.replace(
          this.searchText,
          this.replaceText
        )
        console.log("REPLACED", c)
        var path =
          "org/" +
          this.orgID +
          "/game/" +
          this.results[i].gameID +
          "/missions/" +
          this.results[i].theKey
        console.log("PATH", path)
        var payload = { instructions: c }
        console.log("Payload", payload)
        await db.ref(path).update(payload)
      }
    },
    showSearch(thing) {
      var pos = thing.toLowerCase().indexOf(this.searchText.toLowerCase())
      return thing.substr(pos - 10, 100)
    }
  }
}
</script>
<style css-scoped>
.title {
  font-weight: bold;
}
</style>
